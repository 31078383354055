




















































































import format from '@/filter/dateFormat';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST } from '../store/actions';
import { SET_LIST_PAGE, SET_LIST_SCOPE_OF_FUCTIONS, SET_LIST_SEARCH, SET_LIST_STATE } from '../store/mutations';
import { includesScopeOfFunctions, isClosed } from '../helper';
import { Conservatorship, ConservatorshipList, State } from '@/modules/conservatorship/types';
import { ApiResponse, Pagination } from '@/components/types';
import { PaginatableMixin } from '@/views/mixin';
import ListSearchDialog from '../components/ListSearchDialog.vue';
import ConservatorshipStateSelect from '../components/ConservatorshipStateSelect.vue';
import ScopesOfFunctionsSelect from '@/modules/scopeOfFunctions/components/ScopesOfFunctionsSelect.vue';
import AppTable from '@/components/AppTable.vue';
import CardHeader from '@/components/CardHeader.vue';
import { routeToLocation } from '@/router';

const ConservatorshipListExportDialog = () => import('../components/list/ConservatorshipListExportDialog.vue');
const YearViewDialog = () => import('../components/list/YearViewDialog.vue');
const MonthViewDialog = () => import('../components/list/MonthViewDialog.vue');
const CourtCounterStatisticDialog = () => import('../components/list/CourtCounterStatisticDialog.vue');

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    CardHeader,
    AppTable,
    YearViewDialog,
    MonthViewDialog,
    CourtCounterStatisticDialog,
    ConservatorshipListExportDialog,
    ListSearchDialog,
    ScopesOfFunctionsSelect,
    ConservatorshipStateSelect
  }
})
export default class List extends PaginatableMixin {
  @Conservatorship.State('list') list!: ConservatorshipList;
  @Conservatorship.Mutation(SET_LIST_PAGE) setPage!: (page: number) => void;
  @Conservatorship.Mutation(SET_LIST_STATE) setState!: (state: State | null) => void;
  @Conservatorship.Mutation(SET_LIST_SEARCH) setSearch!: (search: string) => void;
  @Conservatorship.Mutation(SET_LIST_SCOPE_OF_FUCTIONS) setScopeOfFunctions!: (search: string) => void;
  @Conservatorship.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;

  loading: boolean = false;
  error: any = null;

  pagination: Pagination = {
    sortBy: ['fullName'],
    itemsPerPage: 10,
    page: 1,
    descending: false,
    pageCount: 0
  };

  get stateFilter(): State | null {
    return this.$route.query['state'] as State || null;
  }

  set stateFilter(state: State | null) {
    this.setState(state);

    if (!state) {
      const { state, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, state } })).catch(() => {
    });
  }

  get scopeOfFunctionsFilter(): string {
    return this.$route.query['scopeOfFunctions'] as State || '';
  }

  set scopeOfFunctionsFilter(scopeOfFunctions: string) {
    this.setScopeOfFunctions(scopeOfFunctions);

    if (!scopeOfFunctions) {
      const { scopeOfFunctions, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, scopeOfFunctions } })).catch(() => {
    });
  }

  get items() {
    return this.list.items
      .filter((conservatorship) => this.list.state === null || conservatorship.state === this.list.state)
      .filter((conservatorship) => includesScopeOfFunctions(this.list.scopeOfFunctions, conservatorship.scopesOfFunctions))
      .map(({ conservatee, ...conservatorship }) => ({
        ...conservatorship,
        fullName: `${conservatee.surname}, ${conservatee.forename}`,
        stateTrans: this.$i18n.t('conservatorship.state.' + conservatorship.state),
        dateTrans: isClosed(this.list.state) ? format(conservatorship.closed as string, 'dd.MM.yyyy') : format(conservatorship.assigned, 'dd.MM.yyyy'),
        date: isClosed(this.list.state) ? conservatorship.closed : conservatorship.assigned
      }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'fullName' },
      ...(this.isAdmin() ? [{
        align: 'left',
        text: this.$i18n.t('common.conservator'),
        value: 'conservator.name'
      }] : []),
      { align: 'left', text: this.$i18n.t('common.court'), value: 'court' },
      {
        align: 'left',
        text: this.$i18n.t(`common.${isClosed(this.list.state) ? 'closed' : 'assigned'}`),
        value: 'date'
      },
      { align: 'left', text: this.$i18n.t('common.state'), value: 'stateTrans' }
    ];
  }

  itemClasses(conservatorship: Conservatorship) {
    if (conservatorship.state === 'archived') {
      return 'info';
    }

    if (conservatorship.state === 'closed') {
      return 'error';
    }

    if (conservatorship.state === 'suspended') {
      return 'warning';
    }

    return '';
  }

  async created() {
    if (!this.stateFilter) {
      this.stateFilter = this.list.state || null;
    }

    if (!this.scopeOfFunctionsFilter) {
      this.scopeOfFunctionsFilter = this.list.scopeOfFunctions || '';
    }

    if (!this.search) {
      this.search = this.list.search || '';
    }

    try {
      this.loading = true;

      await this.fetch();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
