
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, GET_LIST } from '../store';
import { ScopeOfFunctions } from '@/modules/scopeOfFunctions/types';
import { ApiResponse } from '@/components/types';

const ScopesOfFunctions = namespace('scopeOfFunctions');

@Component({})
export default class ScopesOfFunctionsSelect extends Vue {
  @Prop({ type: String }) value?: string;

  @ScopesOfFunctions.Getter(GET_LIST) list!: ScopeOfFunctions[];
  @ScopesOfFunctions.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;

  get items() {
    return [
      { id: null, name: 'Alle anzeigen' },
      ...this.list.sort((first, second) => first.name.localeCompare(second.name))
    ];
  }

  created() {
    if (this.list.length !== 0) return;

    return this.fetch();
  }
}
