

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ConservatorshipStateSelect from './ConservatorshipStateSelect.vue';
import ScopesOfFunctionsSelect from '@/modules/scopeOfFunctions/components/ScopesOfFunctionsSelect.vue';
import { State } from '../types';

@Component({
  components: {
    ConservatorshipStateSelect,
    ScopesOfFunctionsSelect
  }
})
export default class ListSearchDialog extends Vue {
  @Prop({ type: String }) search!: string;
  @Prop({ type: String }) state!: State | null;
  @Prop({ type: String }) scopesOfFunctions!: string;

  dialog: boolean = false;

  iSearch: string = '';
  iState: State | null = null;
  iScopesOfFunctions = '';

  @Watch('search', { immediate: true })
  onSearch(search: string) {
    this.iSearch = search;
  }

  @Watch('state', { immediate: true })
  onState(state: State) {
    this.iState = state;
  }

  @Watch('scopesOfFunctions', { immediate: true })
  onScopesOfFunctions(scopesOfFunctions: string) {
    this.iScopesOfFunctions = scopesOfFunctions;
  }

  submit() {
    this.$emit('update:search', this.iSearch);
    this.$emit('update:state', this.iState);
    this.$emit('update:scopesOfFunctions', this.iScopesOfFunctions);
    this.dialog = false;
  }

  reset() {
    this.iSearch = this.search;
    this.iState = this.state;
    this.iScopesOfFunctions = this.scopesOfFunctions;
    this.dialog = false;
  }
}
